import React from "react"
import { Container, Section } from "../styles/common/Layout";

const NotFoundPage = () => {
  return (
    <>
      <Section>
        <Container>
          <h1 className="text-center text-4xl font-thin mb-8">SEITE NICHT GEFUNDEN :(</h1>
          <p className="text-center">Da ging was schief... Diese Enttäuschung... diese Traurigkeit!.</p>
        </Container>
      </Section>
    </>
  )
}

export default NotFoundPage;
